import HeaderComponent from "../header";
import "./index.scss"
import 'bootstrap/dist/css/bootstrap.min.css';
import CITY_IMG from "../../assets/images/city-img.png";
import STAR_IMG from "../../assets/images/star-img.png";
import STAR_2_IMG from "../../assets/images/star.png";
import videoLanding from "../../assets/images/landing-video.mp4";
import CITY_ISO_IMG from "../../assets/images/city-iso.png";
import PIN_IMG from "../../assets/images/pin.png";
import FooterComponent from "../footer";
import React from "react";
import UtilityService from "../../utils/utility";
const LandingComponent = () => {


    React.useEffect(()=>{
        UtilityService().setPageTitle("Landing")
    },[])
    const clickContactUs = () => {
        UtilityService().clickSendEvent("Click", "Landing", "Contact us");
        window.open("mailto:mondayclubteam@gmail.com", "_blank");
    }

    const clicktoCampaign = () => {
        UtilityService().clickSendEvent("Click", "Landing", "Campaign");
        window.open("https://mondayclub.io/campaign", "_blank");
    }

    return (
        <div className="container-fluid container-control">
            <HeaderComponent></HeaderComponent>

            <div className="vdo-section">

                <div className="vdo-file" dangerouslySetInnerHTML={
                    {
                        __html: `
        <video class="w-100" loop muted autoplay playsinline>
                    <source src=${videoLanding} type='video/mp4' />
        </video>
        `}} />
            </div>

            <div className="section-1-control">
                <div className="container d-flex flex-column">


                    <img src={PIN_IMG} className="img-pin mx-auto"></img>
                    <span className="text-monday">What is Monday Club Metaverse?</span>
                    <span className="text-desc">
                        No more boring working life with Monday Club Metaverse.
                        Live life to the fullest with our unique Metaverse.
                        Where the MetaOffice will provide a better working environment for people
                        and also enhance the social experience through interaction between people,
                        employers and relax through games. Land owners can create a unique experience for their employees and customers to enjoy.
                    </span>
                </div>
                <img src={STAR_IMG} className="img-star" />
                <div className="img-city mt-auto" >
                </div>
            </div>

            <div className="section-2-control">
                <div className="container d-flex flex-column">
                    <span className="text-header">Our launch partners will provide different uniques <br></br>
                        experience for our community from clothing, <br></br>
                        shopping, communication, investments and lifestyle.</span>

                    <img src={CITY_ISO_IMG} className="img-city-iso" onClick={clicktoCampaign}/>

                    <div className="divinder-red"></div>

                    <span className="text-desc">Monday Club land is currently open for our launch partners, <br></br>

                        NFT holders and token holders to gain access to.<br></br>
                        Please contact our team to request access or acquire <br></br>
                        the land and build something unique for the community.</span>

                    <button className="button-default mx-auto" onClick={clickContactUs}>Contact Us</button>
                </div>

                <img src={STAR_2_IMG} className="img-star" ></img>

            </div>
            <FooterComponent isBlack={false}></FooterComponent>
        </div>
    )
}

export default LandingComponent;

