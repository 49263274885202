import { Button, Container, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.scss";
import LOGOMONDAY from "../../assets/images/logo-with-so.png";
import config from "../../config";
const HeaderComponent = () => {


    const connectWallet = () => {

    }

    return (
        <div className="header-control">
            <Navbar collapseOnSelect expand="md" className="header-control navbar-dark">
                <Container fluid="xxl">
                    <Navbar.Brand href="https://mondayclub.io/" className="d-flex logo-control">
                        <img className="logo-monday" src={LOGOMONDAY} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse className="justify-content-end" >

                        <NavLinkOutside path={config.app.home} title="Home" option="" />
                        <NavLinkOutside path={config.app.campaign} title="Campaigns" option="" />
                        <NavLinkOutside path={config.app.gallery} title="Gallery" option="" />
                        <NavLinkOutside path={config.app.scoutout_service} title="ScoutOut Service" option="" />
                        <NavLinkOutsideLaunchApp path={config.app.launch_app} title="Launch App" option="" />

                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container className="connect-wallet-sm">
                <div className="d-flex justify-content-end align-items-center">
                    <div>
                    </div>
                    <div>
                    </div>
                </div>
            </Container>
        </div>
    )

}

export default HeaderComponent;
function NavLinkOutsideLaunchApp({ path, title, option }) {
    return (
        <Button className="d-flex button-launch-app">
            <a href={path} target="_blank"><span>{title}</span></a>
        </Button>
        
    )
}
function NavLinkOutside({ path, title, option }) {
    return (
        <a href={path} target="_blank" className="nav-link navbar-sm link"><span>{title}</span></a>
    )
}
function NavLink({ path, title }) {
    return (
        <Link to={path} className="nav-link navbar-sm link">{title}</Link>
    )
}

function ButtonConnectWallet({ connectWallet }) {
    return (
        <button className="btn btn-connect" onClick={connectWallet}>Connect wallet</button>
    )
}