const test = {
    title: "",
    ga_4: "G-NDFTVSKH4W",
    ga: "UA-215714235-4",
    app: {
        home: "https://monday-club-test.web.app",
        campaign: "https://monday-club-test.web.app/campaign",
        gallery: "https://monday-club-test.web.app/gallery",
        scoutout_service: "https://scoutout.io/scoutout-system",
        launch_app: "https://app.mondayclub.io"

    },
 
    link_social: {
        discord: "https://discord.gg/DyePrn7w9D",
        telegram: "https://t.me/mondayclub",
        twitter: "https://twitter.com/MondayClubHQ",
        facebook: "https://www.facebook.com/MondayClub.io",
        gitbook: "https://mondayclub.gitbook.io/monday-club/",
        gitbook_team: "https://mondayclub.gitbook.io/monday-club/the-formal-stuff/team",
        gitbook_aboutus: "https://mondayclub.gitbook.io/monday-club/the-formal-stuff/team",
        gitbook_avartar: "https://mondayclub.gitbook.io/monday-club/products/avatar",
        gitbook_tokenomic: "https://mondayclub.gitbook.io/monday-club/the-formal-stuff/tokenomics",
        gitbook_roadmap: "https://mondayclub.gitbook.io/monday-club/the-formal-stuff/roadmap"

    }

};


const prod = {
    title: "",
    ga_4: "G-NDFTVSKH4W",
    ga: "UA-215714235-4",
    app: {
        home: "https://mondayclub.io",
        campaign: "https://mondayclub.io/campaign",
        gallery: "https://mondayclub.io/gallery",
        scoutout_service: "https://scoutout.io/scoutout-system",
        launch_app: "https://app.mondayclub.io"

    },
 
    link_social: {
        discord: "https://discord.gg/DyePrn7w9D",
        telegram: "https://t.me/mondayclub",
        twitter: "https://twitter.com/MondayClubHQ",
        facebook: "https://www.facebook.com/MondayClub.io",
        gitbook: "https://mondayclub.gitbook.io/monday-club/",
        gitbook_team: "https://mondayclub.gitbook.io/monday-club/the-formal-stuff/team",
        gitbook_aboutus: "https://mondayclub.gitbook.io/monday-club/the-formal-stuff/team",
        gitbook_avartar: "https://mondayclub.gitbook.io/monday-club/products/avatar",
        gitbook_tokenomic: "https://mondayclub.gitbook.io/monday-club/the-formal-stuff/tokenomics",
        gitbook_roadmap: "https://mondayclub.gitbook.io/monday-club/the-formal-stuff/roadmap"

    }
};

const config = process.env.REACT_APP_ENVIRONMENT === 'production'
    ? prod 
    : test;

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};