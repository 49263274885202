import React from "react";
import "./index.scss";
import SEA from "../../assets/images/sea.png";
import LAND from "../../assets/images/land.png";
import { Button } from "react-bootstrap";
import MAP from "../../assets/images/land-map.png";
import GQCOMPANY from "../../assets/images/gq.png";
import TVPOOLCOMPANY from "../../assets/images/tv-pool.png";
import BinanceCOMPANY from "../../assets/images/binance.png";
import MAPJSON from "../../assets/json/map-metaoffice.json";
import BLOCK0 from "../../assets/images/sizing/0-block.png";
import { Map } from "../../model/map.model";
import CoordModel, { Location, RawLocation } from "../../model/coord.model";
const MapLanding = () => {
    var selection = [0, 0]; //Which tile we will paint from the menu
    var y = 12;
    var n = 1;
    const tileAtlas = new Image();
    const canvasRef = React.useRef(null);
    const canvasRef1 = React.useRef(null);
    const mapLevel: any = [];
    const coorModel: CoordModel[] = [];
    const drawCoor: CoordModel[] = [];
    const [coordModel, setCoordModel] = React.useState<CoordModel[]>([])
    const [drawCoorModel, setDrawCoorModel] = React.useState<CoordModel[]>([])
    const [atlasCol] = React.useState(MAPJSON.size.height);
    const [tileSize] = React.useState(8);
    const [mapCols] = React.useState(MAPJSON.size.height);
    const [mapRows] = React.useState(MAPJSON.size.width);
    const [typeLand, setTypeLand] = React.useState(LAND)
    const [idLand, setIDLand] = React.useState(0)
    const [isDistrict, setIsDistrict] = React.useState(false);
    const [isShow, setShow] = React.useState(false);
    const [context, setContext] = React.useState<CanvasRenderingContext2D | null>(null);
    const [canvas, setCanvas] = React.useState<HTMLCanvasElement | null>(null);
    const [context1, setContext1] = React.useState<CanvasRenderingContext2D | null>(null);
    const [canvas1, setCanvas1] = React.useState<HTMLCanvasElement | null>(null);
    React.useEffect(() => {


        if (canvasRef.current && canvasRef1.current) {
            var canvas = canvasRef.current as HTMLCanvasElement;
            var canvas1 = canvasRef1.current as HTMLCanvasElement;
            const ctx = canvas.getContext('2d');
            const ctx1 = canvas1.getContext('2d');
            setCanvas(canvas);
            setContext(ctx);
            setCanvas1(canvas1);
            setContext1(ctx1);
            tileAtlas.src = BLOCK0;
            tileAtlas.onload = function () {
                draw(ctx, canvas, ctx1, canvas1);
            }
            // initMap();
        }
    }, [])



    const draw = async (ctx: any, cnv: any, ctx1: any, cnv1: any) => {
        console.log(ctx);
        console.log(cnv);
        if (cnv && ctx) {
            let tileOutputSize = 1 // can set to 1 for 32px or higher
            let updatedTileSize = tileSize * (tileOutputSize) - 1;
            let mapHeight = mapRows * tileSize;
            let mapWidth = mapCols * tileSize
            let mapIndex = 0;
            let sourceX = 0;
            let sourceY = 0;
            let rawX = 0;
            let rawY = 0;
            let startX = MAPJSON.size.start_location[0];
            let startY = MAPJSON.size.start_location[1];
            cnv.width = mapWidth;
            cnv.height = mapHeight;
            cnv1.width = mapWidth;
            cnv1.height = mapHeight;

            const mapSizing = MAPJSON.size.width * MAPJSON.size.height;
            for (var i = 0; i < mapSizing; i++) {
                mapLevel.push(y);
            }
            for (let col = 0; col < mapHeight; col += tileSize) {
                for (let row = 0; row < mapWidth; row += tileSize) {
                    // cnv.className = 'canvas-width canvas-control filter' + mapIndex;
                    // cnv.id = 'filter' + mapIndex;
                    const objLocation: Location = {
                        x: startX,
                        y: startY
                    }

                    const objRawLocation: RawLocation = {
                        x: rawX,
                        y: rawY
                    }

                    const objCoord: CoordModel = {
                        id: mapIndex,
                        rawLocation: objRawLocation,
                        location: objLocation
                    }


                    coorModel.push(objCoord);
                    let tileVal = mapLevel[mapIndex];
                    tileVal -= 1;
                    sourceY = Math.floor(tileVal / atlasCol) * tileSize;
                    sourceX = (tileVal % atlasCol) * tileSize;
                    ctx.drawImage(tileAtlas, sourceX, sourceY, tileSize,
                        tileSize, row * tileOutputSize, col * tileOutputSize,
                        updatedTileSize, updatedTileSize)
                    mapIndex++;
                    startX++;
                    rawX++;
                }
                startY--;
                rawY++;
            }
            setCoordModel(coorModel)
        }
    }

    const handleClickCanvas = (event: any) => {
        event.preventDefault();
        // console.log(event);
    }

    const handleClickBlockAtlas = (e: any) => {
        console.log("Click Alert");

        console.log(e);

    }



    const initMap = async (ctx: any) => {
        if (ctx && canvas1) {

            MAPJSON.map.map((value: Map, index: any) => {
                const tileSize1 = tileSize * value.type.width;
                const size = tileSize1 - 1;
                const sizeMap = tileSize1 - tileSize;
                const blockSize = tileSize;
                let rrealX = 0;
                let rrealY = 0;
                let sizeW = 0;
                let sizeH = 0;
                let setX = 0;
                let setY = 0;
                coordModel.filter((values: CoordModel) => values.location.x == value.location[0])
                    .map((res: CoordModel, index: any) => {
                        rrealX = res.rawLocation.x * blockSize;
                        sizeW = value.type.width;
                        setX = res.rawLocation.x;
                    })

                coordModel.filter((values: CoordModel) => values.location.y === value.location[1])
                    .map((res: CoordModel, index: any) => {
                        rrealY = (res.rawLocation.y * blockSize) - sizeMap;
                        sizeH = value.type.height;
                        setY = res.rawLocation.y;
                    })

                const objLocation: Location = {
                    x: setX,
                    y: setY
                }

                const objRawLocation: RawLocation = {
                    x: sizeW,
                    y: sizeH
                }

                const objCoord: CoordModel = {
                    id: index,
                    rawLocation: objRawLocation,
                    location: objLocation
                }

                drawCoor.push(objCoord);
                let blockAtlas = new Image();
                if (value.type.width === 1) {
                    blockAtlas.id = "blockAtlas-"+index;
                    // console.log("Drawing : Size = " + size + " : X = " + rrealX + " Y = " + rrealY)
                 
                    drawBlock(blockAtlas, ctx, size, rrealX, rrealY, value.image_url);
                }
                // else if (value.type.width === 3) {
                //     blockAtlas.src = require("../../assets" + value.image_url);
                //     drawBlock(blockAtlas, ctx, size, rrealX, rrealY);
                //     // putCompanySize3(tileSize1, value.location[0], value.location[1])
                // }
                // else if (value.type.width === 6) {
                //     blockAtlas.src = require("../../assets" + value.image_url);
                //     drawBlock(blockAtlas, ctx, size, rrealX, rrealY);
                //     // putCompanySize6(tileSize1, value.location[0], value.location[1])
                // }
                // else if (value.type.width === 12) {
                //     blockAtlas.src = require("../../assets" + value.image_url);
                //     drawBlock(blockAtlas, ctx, size, rrealX, rrealY);
                //     // putCompanySize12(tileSize1, value.location[0], value.location[1])
                // }
                // else if (value.type.width === 24) {

                //     blockAtlas.id = "blockAtlas-" + index;
                //     if (value.is_available) {
                //         // console.log("Drawing : Size = " + size + " : X = " + realX + " Y = " + realy)
                //         blockAtlas.src = require("../../assets" + value.image_url);
                //     } else {
                //         // console.log("Drawing  GQ : index = " + index)
                //         // console.log("Drawing  GQ : startX = " + value.location[0] + " : startY = " + value.location[1])
                //         // console.log("Drawing  GQ : Size = " + size + " : X = " + realX + " Y = " + realy)
                //         blockAtlas.src = value.image_url;
                //     }
                //     drawBlock(blockAtlas, ctx, size, rrealX, rrealY);


                //     // const elemetnId = document.getElementById("blockAtlas-2092");



                // }


            })

            setDrawCoorModel(drawCoor);
            if (canvas1 && context1) {
                // listener, using W3C style for example    
                canvas1.addEventListener('click', function (e: any) {
                    const { x, y } = e.target.getBoundingClientRect();
                    const mouseX = e.clientX - x;
                    const mouseY = e.clientY - y;
                    console.log("mouseX : " + Math.floor(mouseX / tileSize) + " mouseY : " + Math.floor(mouseY / tileSize))
                    var rect = collides(drawCoor, e.offsetX, e.offsetY, e);
                    console.log(e.offsetX);
                    if (rect) {
                        alert("true");
                    } else {
                        console.log('no collision');
                    }
                }, false);
            }
        }
    }
    function collides(rects: CoordModel[], x: any, y: any , e:any) {
        var isCollision = false;

        for (var i = 0, len = rects.length; i < len; i++) {
            var left = rects[i].location.x,
                right = rects[i].location.x + rects[i].rawLocation.x;
            var top = rects[i].location.y,
                bottom = rects[i].location.y + rects[i].rawLocation.y;

            if (rects[i].rawLocation.y == 1) {
                
                console.log("index : " + rects[i].id);
                console.log("x : " + rects[i].location.x + " y : " + rects[i].location.y)
                console.log("x : " + x + " y : " + y)
                console.log("left : " + left + " top : " + top)
                console.log("width : " + rects[i].rawLocation.x + " height : " + rects[i].rawLocation.y)
            }

            if (right >= x
                && left <= x
                && bottom >= y
                && top <= y) {
                isCollision = true;
            }
        }
        return isCollision;
    }
    function convertToLocation(rawX: any, rawY: any) {
        let realX = rawX - 74;
        let realY = (74 - rawY);
        return [realX, realY];
    }
    function handleMouseDown(e: any) {
        console.log(e);
        if (context1 && canvas1) {
            var rect = canvas1.getBoundingClientRect();
            var X = e.x - rect.left;
            var Y = e.y - rect.top;
            var x = Math.floor(X / tileSize);
            var y = Math.floor(Y / tileSize);

            console.log("x : " + x + " y : " + y);
        }

        // let resGetCoor = getCoords(e);
        // let convert = convertToLocation(resGetCoor[0], resGetCoor[1]);
        // console.log(resGetCoor[0] + " ," + resGetCoor[1]);
        // console.log(convert[0] + " ," + convert[1]);
        // console.log("=========================================");

        // for (var i = 0; i < MAPJSON.map.length; i++) {
        //     let value = MAPJSON.map[i];
        //     if (hit(value, convert[0], convert[1])) {
        //         console.log("Yes : " + value.image_url);
        //         console.log("Hit : " + value.location[0] + " : width = " + value.type.width);
        //         break;
        //     }

        // }

    }
    function hit(rect: any, x: any, y: any) {

        return (x >= rect.location[0] && x <= rect.location[0] + (rect.type.width * tileSize) && y >= rect.location[1] && y <= rect.location[1] + (rect.type.height * tileSize));
    }
    function drawImageScaled(img: any, ctx: CanvasRenderingContext2D, tileSize: any, rowX: any, rowY: any) {
        ctx.strokeStyle = '#f00';  // some color/style
        ctx.lineWidth = 2;         // thickness
        ctx.fillStyle = 'rgba(225,225,225,0)';
        const rect = new Path2D();
        rect.rect(rowX, rowY,
            tileSize, tileSize);
        ctx.drawImage(img,
            0, 0,
            img.width, img.height,
            rowX, rowY,
            tileSize, tileSize);
        ctx.strokeRect(rowX, rowY,
            tileSize, tileSize)

        ctx.fill(rect);

       
        // Listen for mouse moves
        // check if context exist


    }


    const drawBlock = (blockAtlas: HTMLImageElement, context: CanvasRenderingContext2D, size: any, realX: any, realy: any, url: any) => {
        blockAtlas.onload = function () {
            drawImageScaled(blockAtlas, context, size, realX, realy)
            canvas?.appendChild(blockAtlas)
            document.body.appendChild(blockAtlas);

        }
        blockAtlas.src = require("../../assets" + url);
        blockAtlas.onclick = handleEvent;
       
    }



    //Utility for getting coordinates of mouse click
    function getCoords(e: any) {
        // const sizeCor = tileSize;
        // const { x, y } = e.target.getBoundingClientRect();
        // const mouseX = e.clientX - x;
        // const mouseY = e.clientY - y;
        // const realX = Math.floor(mouseX / sizeCor) + MAPJSON.size.start_location[0]
        // const realY = Math.floor(mouseY / sizeCor) + (MAPJSON.size.start_location[1]) * -1
        // console.log("x : " + Math.floor(mouseX / sizeCor) + " y : " + Math.floor(mouseY / sizeCor));



        // return [Math.floor(mouseX / sizeCor), Math.floor(mouseY / sizeCor)];
    }



    // React.useEffect(() => {
    //     setTimeout(() => {
    //         console.log("World!");
    //         console.log(context);
    //         tileAtlas.src = MAP;
    //         tileAtlas.onload = function () {
    //             draw();
    //         }
    //     }, 2000);

    // }, [context])

    function handleEvent(event: any) {
        console.log(event);
        // selection = getCoords(event);
        // var index = (mapCols * selection[1]) + (selection[0] + 1);

        // console.log("index Click : " + index);
        // setIDLand(index);
        // setShow(true);
        // if (mapLevel[index - 1] === 4) {
        //     setTypeLand(SEA);
        //     setIsDistrict(true);
        // }
        // else {
        //     setTypeLand(LAND);
        //     setIsDistrict(false);
        // }
        // let tileSetHtml = document.getElementsByClassName("tileset-container_selection") as HTMLCollectionOf<HTMLElement>;
        // tileSetHtml[0].style.left = selection[0] * tileSize + "px";
        // tileSetHtml[0].style.top = selection[1] * tileSize + "px";
    }
    return (
        <div className="container-control d-flex flex-column">
            <Button onClick={() => initMap(context1)}>Add Company</Button>
            <div className="container grid-control">
                <div className="d-inline-block">
                    <div className="tileset-container">
                        <canvas className="canvas-width canvas-control" ref={canvasRef} />
                        <canvas className="canvas-width canvas-control-1" ref={canvasRef1} />
                        {/* <canvas className="canvas-width canvas-control-3" ref={canvasSize3} />
                        <canvas className="canvas-width canvas-control-6" ref={canvasSize6} />
                        <canvas className="canvas-width canvas-control-12" ref={canvasSize12} />
                        <canvas className="canvas-width canvas-control-24" ref={canvasSize24} onClick={handleClickCanvas} /> */}
                    </div>
                </div>

                {isShow ? <div className="info-control d-flex flex-column">
                    <h1 className="text-header">Choose land</h1>
                    <div className="d-flex">
                        <img src={typeLand} className="img-land"></img>
                    </div>
                    {
                        isDistrict ? <span className="cor-text text-center">Cannot buy district area</span> :
                            <div className="d-flex flex-column">

                                <span className="cor-text">ID Land : {idLand}</span>
                                <span className="own-text">Owner Address : null</span>
                                <button className="button-buyland">Buy this land</button>
                            </div>
                    }


                </div> : null}

            </div>


        </div>
    )
}


export default MapLanding;