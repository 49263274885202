import React from 'react';
import logo from './logo.svg';
import './App.scss';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MapLanding from './components/map';
import LandingComponent from './components/landing';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingComponent />} />
          <Route path="/view" element={<MapLanding />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
